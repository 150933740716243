var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ staticClass: "indigo ma-3", attrs: { text: "" } }, on),
                [_vm._v(" " + _vm._s(_vm.caption) + " ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _vm._v("Изменить баланс карты"),
          ]),
          _c(
            "div",
            { staticClass: "ma-5" },
            [
              _vm._v(
                " Номер: " + _vm._s(_vm.item.number ? _vm.item.number : "--")
              ),
              _c("br"),
              _vm._v(
                " Владелец: " + _vm._s(_vm.item.owner ? _vm.item.owner : "--")
              ),
              _c("br"),
              _vm._v(" Чат: " + _vm._s(_vm.item.chatId) + " "),
              _c("hr"),
              _vm._v(" Баланс: " + _vm._s(_vm.item.balance) + " "),
              _c("v-btn", { staticClass: "ma-1", on: { click: _vm.max } }, [
                _vm._v("MAX в сумму"),
              ]),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "ma-5",
                  attrs: { id: "rg", row: "", dense: "" },
                  model: {
                    value: _vm.radios,
                    callback: function ($$v) {
                      _vm.radios = $$v
                    },
                    expression: "radios",
                  },
                },
                [
                  _c("v-radio", { attrs: { label: "- Списать", value: "1" } }),
                  _c("v-radio", {
                    attrs: { label: "+ Начислить", value: "2" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "ma-5",
                  attrs: { id: "rg2", row: "", dense: "" },
                  model: {
                    value: _vm.radios2,
                    callback: function ($$v) {
                      _vm.radios2 = $$v
                    },
                    expression: "radios2",
                  },
                },
                [
                  _c("span", [_vm._v("начислить/списать из")]),
                  _c("br"),
                  _c("v-radio", { attrs: { label: "Пришло ", value: "1" } }),
                  _c("v-radio", { attrs: { label: "Долг", value: "2" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("v-text-field", {
                staticClass: "ma-5",
                attrs: {
                  label: "Сумма",
                  type: "number",
                  outlined: "",
                  clearable: "",
                },
                model: {
                  value: _vm.sum,
                  callback: function ($$v) {
                    _vm.sum = $$v
                  },
                  expression: "sum",
                },
              }),
              _c("v-text-field", {
                staticClass: "ma-5",
                attrs: { label: "Комментарий", outlined: "", clearable: "" },
                model: {
                  value: _vm.comment,
                  callback: function ($$v) {
                    _vm.comment = $$v
                  },
                  expression: "comment",
                },
              }),
              _c("v-file-input", {
                staticClass: "ma-5",
                attrs: {
                  label: "Скрин",
                  accept: "image/*",
                  "show-size": "",
                  "truncate-length": "15",
                  outlined: "",
                  clearable: "",
                },
                model: {
                  value: _vm.img,
                  callback: function ($$v) {
                    _vm.img = $$v
                  },
                  expression: "img",
                },
              }),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Отмена ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.ok },
                },
                [_vm._v(" Ок ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }