<template>


  <v-dialog
      v-model="dialog"
      width="700"
  >
    <template v-slot:activator="{ on }">
      <v-btn class="indigo ma-3"  text v-on="on">  {{ caption }}  </v-btn>
    </template>

    <v-card>
      <v-card-title primary-title>Изменить баланс карты</v-card-title>

      <div class="ma-5">
        Номер: {{ item.number ? item.number : "--" }}<br>
        Владелец: {{ item.owner ? item.owner : "--" }}<br>
        Чат: {{ item.chatId }}
        <hr>

        Баланс:  {{ item.balance }}
        <v-btn class="ma-1" @click="max">MAX в сумму</v-btn>


      </div>

      <div>
        <v-radio-group id="rg" row dense v-model="radios" class="ma-5">
          <v-radio label="- Списать" value='1'></v-radio>
          <v-radio label="+ Начислить" value='2'></v-radio>
        </v-radio-group>
      </div>

      <div>
        <v-radio-group id="rg2" row dense v-model="radios2" class="ma-5">
            <span>начислить/списать из</span><br>
            <v-radio label="Пришло " value='1'></v-radio>
            <v-radio label="Долг" value='2'></v-radio>
        </v-radio-group>

      </div>

      <div>

        <v-text-field
            class="ma-5"
            v-model="sum"
            label="Сумма"
            type="number"
            outlined
            clearable
        ></v-text-field>

        <v-text-field
            class="ma-5"
            v-model="comment"
            label="Комментарий"

            outlined
            clearable
        ></v-text-field>

        <v-file-input
            class="ma-5"
            v-model="img"
            label="Скрин"
            accept="image/*"
            show-size
            truncate-length="15"

            outlined
            clearable


        ></v-file-input>
      </div>



      <v-divider></v-divider>

      <v-card-actions>

        <v-spacer></v-spacer>

        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
          Отмена
        </v-btn>

        <v-btn
            color="primary"
            text
            @click="ok"
        >
          Ок
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>


export default {
  name: 'CardBalanceEditDialog',


  data: function () {
    return {
      img:"",
      dialog: false,
      sum: 0,
      radios: '1',
      radios2: '1',
      comment:""
    }
  },

  props: ['item', 'mode', 'caption'],

  watch: {},

  methods: {

    imgselect:function (file){
      this.img = file.name
    },

    max:function (){

      this.sum = this.item.balance

    },

    ok: function () {

      if (!this.comment){
        alert('Поле комментарий обязательно к заполнению')
        return
      }


      this.dialog = false;
      this.$emit('ok', this.item, parseInt(this.radios), parseInt(this.radios2), this.sum, this.comment, this.img)
    }
  },

}
</script>
