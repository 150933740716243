var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-3" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _c("div", { staticClass: "Withdraws" }, [
        _c("h3", [
          _vm._v("Баланс карт - " + _vm._s(_vm.getBrand()) + " payments"),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _vm._m(0),
          _c(
            "v-form",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Chat ID Filter",
                          outlined: "",
                          clearable: "",
                          type: "number",
                          min: "0",
                          oninput: "validity.valid||(value='');",
                        },
                        model: {
                          value: _vm.chatId,
                          callback: function ($$v) {
                            _vm.chatId = $$v
                          },
                          expression: "chatId",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.isOperator
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "2" } },
                        [
                          _c("CurrencySelect", {
                            on: { changed: _vm.currencychanged },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", [
        _vm._v(
          "Сумма балансов: " +
            _vm._s(
              _vm.sumRub.toLocaleString("ru-RU", {
                style: "currency",
                currency: "RUB",
              })
            )
        ),
      ]),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              items: _vm.cards,
              headers: _vm.headers,
              "sort-by": "id",
              "sort-desc": "",
              dense: "",
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",

                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [50, 100, 200, -1],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.id",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.id))]
                },
              },
              {
                key: "item.number",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.number))]
                },
              },
              {
                key: "item.owner",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.owner))]
                },
              },
              {
                key: "item.bank",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(item.bank ? item.bank.name : "--")),
                  ]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(item.status ? "Активна" : "Выключена") + " "
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "indigo",
                        attrs: { dense: "", text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.setActive(item, !item.status)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.status ? "Откл" : "Вкл") + " ")]
                    ),
                  ]
                },
              },
              {
                key: "item.chatId",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.chatId))]
                },
              },
              {
                key: "item.balance",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(item.balance.toLocaleString("ru-RU"))),
                  ]
                },
              },
              {
                key: "item.curr",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.get$TickerByCountry(item.bank.country)) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    !_vm.isOperator
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "orange",
                            attrs: { dense: "", text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.drop(item)
                              },
                            },
                          },
                          [_vm._v("Сбр.")]
                        )
                      : _vm._e(),
                    _c("CardBalanceEditDialog", {
                      attrs: { caption: "Кор.", mode: "edit", item: item },
                      on: { ok: _vm.save },
                    }),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          dense: "",
                          to: "/history/" + item.id,
                          color: "primary",
                        },
                      },
                      [_vm._v("Ист.кор.")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("strong", [_vm._v("Фильтр")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }